import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "overlay", "content"];

    connect() {
      window.stimulusControllers = window.stimulusControllers || {}
      window.stimulusControllers["loadingModal"] = this;
    }

    show(e) {
      this.containerTarget.classList.add("fixed");
      this.overlayTarget.classList.remove("hidden");
      this.contentTarget.classList.remove("hidden");
    }

    hide() {
      this.containerTarget.classList.remove("fixed");
      this.overlayTarget.classList.add("hidden");
      this.contentTarget.classList.add("hidden");
    }

    showIfConfirmed(event) {
      if (event.detail[0] !== true) {
        event.preventDefault();
      } else {
        this.show(event)
      }
    }
}
