import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["uuid", "resourceValue", "container", "dropdown", "ulBox"]

  focusout(){
    if (!this.containerTarget.contains(event.relatedTarget)){
      this.dropdownTarget.classList.add('invisible');
    }
  }

  select(){
    for (const li of this.ulBoxTarget.getElementsByTagName('li')){
      li.classList.remove('bg-indigo-50');
      let x = li.getElementsByClassName("svg-select")[0].classList.add('invisible');
    }
    event.currentTarget.classList.add('bg-indigo-50');
    event.currentTarget.getElementsByClassName("svg-select")[0].classList.remove('invisible');

    this.resourceValueTarget.innerHTML = event.currentTarget.dataset.selectedValue;
    this.uuidTarget.value = event.currentTarget.dataset.uuid;
  }
}
