import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["fileName", "fileInput", "contractSelector", "btnApprove", "checkGenerateBooklet", "tooltip", "fileField"];

  static values = { tab: String }

  connect() {
    super.connect();
  }

  selectContract(event) {
    this.selectFieldWithSpin(event, 'select_contract_for_payment_import')
  }

  selectScaffold(event) {
    this.selectFieldWithSpin(event, 'select_scaffold_for_payment_import')
  }

  selectInstallmentNumber(event) {
    this.selectFieldWithSpin(event, 'select_installment_number_for_payment_import')
  }

  selectInstallmentId(event) {
    this.selectFieldWithSpin(event, 'select_installment_id_for_import_item')
  }

  selectFieldWithSpin(event, reflex_method) {
    this.showSpin(event)
    this.stimulateEvent(event, `Integrations::CNAB::CobrancaFileImportReflex#${reflex_method}`)
  }

  showSpin(event) {
    const target = event.target;
    const spin = "<td colspan=\"8\"><div class=\"col-span-4 lds-dual-ring\"></div></td>";
    const row = target.parentElement.parentElement
    row.innerHTML = spin
  }

  stimulateEvent(event, reflex_str){
    const this_reference = this;
    setTimeout(function(){
      this_reference.stimulate(reflex_str, event.target);
    }, 30);
  }

  chooseFile() {
    this.fileInputTarget.click();
  }

  fileChoosed(event) {
    this.fileNameTarget.value = event.currentTarget.value
      .split(/(\\|\/)/g)
      .pop();

    if(this.fileNameTarget.value){
      this.fileFieldTarget.classList.remove('hidden')
    } else {
      this.fileFieldTarget.classList.add('hidden')
    }
  }

  removeFile() {
    this.fileNameTarget.value = null
    this.fileFieldTarget.classList.add('hidden')
  }
}
