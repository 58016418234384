import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['customerDropdown', 'customerHidden', 'customerInput',
                    'interestValueInput', 'penaltyValueInput', 'discountValueInput']

  selectCustomer(event) {
    this.customerHiddenTarget.value = event.currentTarget.dataset['uuid'];
    this.customerInputTarget.value = event.currentTarget.dataset['name'];
    this.customerDropdownTarget.classList.add('invisible');
  }

  focusInCustomerDropdown(event) {
    this.customerDropdownTarget.classList.remove('invisible');
  }

  toggleInterestValueInput(event) {
    this.interestValueInputTarget.value = 0;
    this.interestValueInputTarget.classList.toggle('hidden');
  }

  togglePenaltyValueInput(event) {
    this.penaltyValueInputTarget.value = 0;
    this.penaltyValueInputTarget.classList.toggle('hidden');
  }

  toggleDiscountValueInput(event) {
    this.discountValueInputTarget.value = 0;
    this.discountValueInputTarget.classList.toggle('hidden');
  }

  toogleCustomerDropdown(event) {
    this.customerDropdownTarget.classList.toggle('invisible')
  }
}
