import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ['counter', 'multiDownloadLink', 'showWhenNoneSelected', 'showWhenAnySelected', 'showWhenOnlySelected', 'checkboxAccordion']

  connect() {
    super.connect()

    window.stimulusControllers = window.stimulusControllers || {}
    window.stimulusControllers["checkboxSelectMultiple"] = this
  }

  toggle (e) {
    e.preventDefault()

    this.checkboxTargets
      .filter(c => !c.disabled)
      .forEach(checkbox => {
        checkbox.checked = e.target.checked
      })

    this.updateDependents()
  }

  refresh () {
    super.refresh()
    this.updateDependents()
  }

  updateDependents() {
    this.updateCounter()
    this.updateMultiDownloadLink()
    this.toggleDependentsVisibility()
    this.updateAccordions()
  }

  updateCounter() {
    if (!this.hasCounterTarget) {
      return false
    }

    if (this.isAnyChecked) {
      this.counterTarget.classList.remove('hidden')
      this.counterTarget.querySelector('[data-component=count]').innerHTML = this.checked.length
    } else {
      this.counterTarget.classList.add('hidden')
      this.counterTarget.querySelector('[data-component=count]').innerHTML = 0
    }
  }

  updateMultiDownloadLink () {
    if (!this.hasMultiDownloadLinkTarget) {
      return false
    }

    this.multiDownloadLinkTargets.forEach(multiDownloadLinkTarget => {
      let addr = new URL(multiDownloadLinkTarget.href)
      addr.searchParams.delete('resource_ids[]')
      this.checked.forEach(checkbox => {
        addr.searchParams.append('resource_ids[]', checkbox.value)
      })
      multiDownloadLinkTarget.setAttribute('href', addr)
    })

  }

  toggleDependentsVisibility () {
    if (!this.hasShowWhenAnySelectedTarget && !this.hasShowWhenOnlySelected) {
      return false
    }

    if (this.isAnyChecked) {
      this.showWhenNoneSelectedTargets.forEach(element => {
        element.classList.add('hidden')
      })
      this.showWhenAnySelectedTargets.forEach(element => {
        element.classList.remove('hidden')
      })
      this.showWhenOnlySelectedTargets.forEach(element => {
        const filteredElements = this.checked.filter(checkbox => {
          return checkbox.classList.contains(element.dataset.filterClass)
        });

        if (filteredElements.length > 0 && filteredElements.length === this.checked.length) {
          element.classList.remove('hidden')
        } else {
          element.classList.add('hidden')
        }
      })
    } else {
      this.showWhenAnySelectedTargets.forEach(element => {
        element.classList.add('hidden')
      })
      this.showWhenNoneSelectedTargets.forEach(element => {
        element.classList.remove('hidden')
      })
      this.showWhenOnlySelectedTargets.forEach(element => {
        element.classList.add('hidden')
      })
    }
  }

  updateAccordions () {
    const accordionsChecked = this.getAllAccordionChecked()
    this.checkboxAccordionTargets.forEach(e => e.checked = false);

    accordionsChecked.forEach(year => {
      document.getElementById(`checkbox-accordion-${year}`).checked = true;
    });
  }

  getAllAccordionChecked () {
    return this.checked.map(e => { return e.attributes.year.value })
  }

  get isAnyChecked () {
    return (this.checked.length > 0)
  }
}
