import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['delayed', 'placeholder'];

  connect() {
    this.resetTexts();
    this.resetVisibility();
  }

  open(){
    this.resetVisibility();
    this.activateCountDown();
  }

  close(){
    this.resetVisibility();
    this.resetTexts();
  }

  resetTexts(invert = false){
    this.setInnerText(this.delayedTarget, this.delayedTarget.dataset.text)
    this.setInnerText(this.placeholderTarget, this.placeholderTarget.dataset.text)
  }

  resetVisibility(delay_over = false) {
    let to_invisible = delay_over ? this.placeholderTarget : this.delayedTarget
    let to_show = delay_over ? this.delayedTarget : this.placeholderTarget
    to_invisible.classList.add('hidden');
    to_show.classList.remove('hidden');
  }

  setInnerText(el, text){
    el.getElementsByClassName("button_span_text")[0].innerHTML = text
  }

  nSecondsFromNow(n){
    let date = new Date();
    date.setSeconds(date.getSeconds() + n);
    return date.getTime();
  }

  get countdown_seconds(){
    const parsed = parseInt(this.data.get("seconds"), 10);
    return isNaN(parsed) ? 10 : parsed
  }

  activateCountDown(){
    let countDownDate = this.nSecondsFromNow(this.countdown_seconds);
    let original_text = this.placeholderTarget.dataset.text;
    let controller = this

    let x = setInterval(function() {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element placeholder
      controller.setInnerText(controller.placeholderTarget, original_text + ' ('+ seconds + ')');

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        controller.resetTexts();
        controller.resetVisibility(true)
      }
    }, 1000);
  }
}
