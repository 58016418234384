import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["documents", "form", "documentsList"];

  connect() {
    this.documentsTarget.addEventListener('change', (event) => {
      Rails.fire(this.formTarget, 'submit');
    });
  }

  chooseDocument(event) {
    event.preventDefault();
    this.documentsTarget.click();
  }

  success(event) {
    let [data, status, xhr] = event.detail;

    this.documentsListTarget.innerHTML = data.body.outerHTML;
    this.documentsTarget.value = '';

    window.stimulusControllers.loadingModal.hide();
  }

  error() {
    this.documentsTarget.value = '';
    window.stimulusControllers.loadingModal.hide();
  }
}
