import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "customDiscountAmountInput",
    "totalAnticipationLabel",
  ];

  recalculateAmounts() {
    const totalAnticipation = parseFloat(this.data.get("totalAnticipation")) || 0;
    const discountAmount = parseFloat(this.customDiscountAmountInputTarget.value) || 0;

    let amount = totalAnticipation - discountAmount;

    this.totalAnticipationLabelTarget.innerText = amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }
}
