import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  showDetails(event) {
    const importItemId = event.currentTarget.dataset.importItemId;
    if (!importItemId) return;

    this.getControllerByIdentifier('loading-modal').show();
    this.stimulate('Banking::FileImportsReflex#show_details', importItemId);
  }

  afterReflex(element, reflex, noop, reflexId) {
    this.getControllerByIdentifier('loading-modal').hide();
    this.getControllerByIdentifier('banking--sidebar').show();
  }
}
