import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["editedField", "confirmationModal", "loadingModal", "successModal", "successModalMessage",
                    "errorModal", "errorModalMessage", "cancelButton", "cancelModal"];

  ask(event) {
    if (this.editedFieldTarget.checkValidity()) {
      event.preventDefault();

      let modalController = this.application.getControllerForElementAndIdentifier(
        this.element,
        "modal"
      )

      modalController.open(event)
    }

    if (this.cancelButtonTarget.checkValidity()) {
      event.preventDefault();

      let modalController = this.application.getControllerForElementAndIdentifier(
        this.element,
        "modal"
      )

      modalController.open(event);
    }
  }

  askWithoutValidation(event) {
    event.preventDefault();

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "modal"
    )

    modalController.open(event)
  }

  confirm(event) {
    event.preventDefault();
    Rails.fire(this.element, 'submit');
    this.confirmationModalTarget.classList.add("hidden");
    this.loadingModalTarget.classList.remove("hidden");
  }

  cancelContract(event) {
    event.preventDefault();
    Rails.fire(this.element, 'submit');
    this.cancelModalTarget.classList.add("hidden");
    this.loadingModalTarget.classList.remove("hidden");
  }

  success(event) {
    let [data, status, xhr] = event.detail;

    this.loadingModalTarget.classList.add("hidden")
    this.successModalTarget.classList.remove("hidden")
    this.successModalMessageTarget.value = data.message
    this.successModalMessageTarget.innerHTML = data.message
  }

  error(event) {
    let [data, status, xhr] = event.detail;
    if (data.errors === undefined) return;

    this.loadingModalTarget.classList.add("hidden")
    this.errorModalTarget.classList.remove("hidden")
    for (let i = 0; i < data.errors.length; i++) {
      // Create the list item:
      let item = document.createElement('li');
      // Set its contents:
      item.appendChild(document.createTextNode(data.errors[i]));
      // Add it to the list:
      this.errorModalMessageTarget.appendChild(item);
    }
  }

  closeAndReload(event) {
    event.preventDefault();
    window.location.reload();
  }
}
