import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['body'];

  connect() {
    const date = new Date();
    const id = date.getFullYear();
    document.getElementById(`accordion-collapse-${id}`).classList.remove('hidden');
    this.toggleIcon(id);
  }

  toggle(event) {
    const id = event.currentTarget.id;
    const mainElementClass = document.getElementById(`accordion-collapse-${id}`).classList;

    mainElementClass.toggle('hidden');

    this.toggleIcon(id);
  }

  toggleIcon(id) {
    const mainIcon = document.getElementById(`accordion-icon-${id}`);

    mainIcon.style.transform = mainIcon.style.transform == '' ? 'rotate(180deg)' : '';
  }

  closeAll(id) {
    this.bodyTargets.map(e => {
      if (e.id !== `accordion-collapse-${id}`) {
        e.classList.add('hidden');

        const year = e.id.slice(-4);
        const icon = document.getElementById(`accordion-icon-${year}`);

        icon.style.transform = '';
      }
    });
  }
}
