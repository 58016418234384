import ApplicationController from '../application_controller'
import CableReady from 'cable_ready'

export default class extends ApplicationController {

  static targets = ['filterForm', 'contractIds', 'loading', 'newtable', 'checkbox'];

  static values = { fileimport: String }

  contractIdsList = [];

  connect () {
    super.connect();

    if (!this.hasFileimportValue) return;

    this.channel = this.application.consumer.subscriptions.create(
      { channel: 'SiengeChannel', id: this.fileimportValue },
      { received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      })
  }

  disconnect () {
    this.channel.unsubscribe()
  }

  uncheckAll(){
    this.checkboxTargets.map(el => el.checked = false);
  }

  updateModal(){
    this.getControllerByIdentifier('modal').open();
    this.stimulate('Integrations::SiengeContractsReflex#modal_load', event.currentTarget.dataset.contractId);
  }

  selectContract(event) {
    const target = event.target;
    const id = target.attributes["data-contract-id"].value;

    if(event.target.checked){
      this.contractIdsList.push(id)
    } else {
      this.contractIdsList = this.contractIdsList.filter(contract_id => contract_id != id)
    }

    document.getElementById("contractsCount").innerText = this.contractIdsList.length
  }

  beforeReflex(anchorElement) {
    const { reflex } = anchorElement.dataset
    if (reflex === undefined) return;

    if (reflex.match(/open_load_modal/)){
      this.loadingTarget.classList.remove('hidden');
      this.newtableTarget.classList.add('hidden');
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}

