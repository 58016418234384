import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['initialDateInput', 'finalDateInput', 'monthSelector', 'search'];
  
  changeMonth(event) {
    this.initialDateInputTarget.value = '';
    this.finalDateInputTarget.value = '';
  }

  applyFilter(event) {
    const initialDateValue = this.initialDateInputTarget.value;
    const finalDateValue = this.finalDateInputTarget.value;

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('page', '1');
    if (this.searchTarget.value) {
      searchParams.set('q', this.searchTarget.value);
    } else {
      searchParams.delete('q');
    }

    if ( (initialDateValue !== '') && (finalDateValue !== '') ) {
      if (this.initialDateInputTarget.valueAsDate > this.finalDateInputTarget.valueAsDate) {
        alert(this.data.get('invalidPeriodMessage'));
        return;
      } 
      else {
        searchParams.set('month', 'date_range_filter');
        searchParams.set('initial_date', initialDateValue);
        searchParams.set('final_date', finalDateValue);
      }
    }
    else {
      searchParams.set('month', this.monthSelectorTarget.value);
      searchParams.delete('initial_date');
      searchParams.delete('final_date');
    }

    searchParams.set('state', searchParams.get('state') || 'deposited');

    window.location.search = searchParams.toString();
  }
}
