import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["updatedInfo", "updateButton", "clipboardButton"]

  refit(event) {
    const button = event.currentTarget
    button.innerHTML = '<svg class="fill-current w-24 h-24 lg:w-16 lg:h-16 mr-16 lg:mr-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M22,8l-4-4v3H3v2h15v3L22,8z"/><path d="M2,16l4,4v-3h15v-2H6v-3L2,16z"/></g></g></svg><span>&nbsp; Aguarde...</span>'
    button.setAttribute("disabled", "disabled")

    setTimeout(() => {
      this.updateButtonTarget.classList.add("hidden")
      this.clipboardButtonTarget.classList.remove("hidden")
      this.updatedInfoTarget.classList.remove("hidden")
    }, 1500)
  }
}
