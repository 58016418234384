import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["enabledButton", "disabledButton", "documentsList"];

  connect() {
    const usesGiro = this.data.get('uses-giro');

    if(usesGiro === 'true'){
      this.observer = new MutationObserver((mutationsList, observer) => {
        this.toggleButtons();
      });

      this.observer.observe(this.documentsListTarget, { childList: true, attributes: true, subtree: true });

      this.toggleButtons();
    }
  }

  toggleButtons() {    
    if (this.documentsListTarget.children[0].children.length) {
      this.enabledButtonTarget.classList.remove('hidden');
      this.disabledButtonTarget.classList.add('hidden');
    } else {
      this.enabledButtonTarget.classList.add('hidden');
      this.disabledButtonTarget.classList.remove('hidden');
    }
  }

  disconnect() {
    this.observer.disconnect();
  }
}
