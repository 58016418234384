import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'slide'];

  change() {
    if(this.buttonTarget.classList.contains('bg-gray-200')){
      this.buttonTarget.classList.remove('bg-gray-200');
      this.buttonTarget.classList.add('bg-indigo-600');

      this.slideTarget.classList.remove('translate-x-0')
      this.slideTarget.classList.add('translate-x-5')
    } else {
      this.buttonTarget.classList.remove('bg-indigo-600');
      this.buttonTarget.classList.add('bg-gray-200');

      this.slideTarget.classList.remove('translate-x-5')
      this.slideTarget.classList.add('translate-x-0')
    }
  }
}
