import {Controller} from "stimulus";

export default class extends Controller {

  static targets = ['street', 'neighbourhood', 'city', 'state', 'zipcode', 'number', 'realtorSelect']

  connect(){
    if(this.zipcodeTarget.value.length == 0) {
      this.putRealtorZipcode();
    }
  }

  setAllReadOnly(value) {
    this.streetTarget.readOnly = value;
    this.neighbourhoodTarget.readOnly = value;
    this.cityTarget.readOnly = value;
    this.stateTarget.readOnly = value;
  }

  setTargetUsingValues(street, neighbourhood, city, state) {
    this.streetTarget.value = street;
    this.neighbourhoodTarget.value = neighbourhood;
    this.cityTarget.value = city;
    this.stateTarget.value = state;
  }

  setTargetsUsingAddr(addr) {
    this.setTargetUsingValues(addr.street, addr.neighbourhood,
      addr.city, addr.state)
  }

  prepareParams() {
    this.stateTarget.readOnly = false;
  }

  //{ ok: 0, empty: 10, size_error: 11, not_found: 12, unknown: 13 }
  processAddressesApiResponse(data) {
    let zipError = document.getElementById("zipcode-js-error");
    if(data.status == 0) { //ok
      this.setTargetsUsingAddr(data.address);
      this.setAllReadOnly(false);
      this.stateTarget.readOnly = true;
      this.numberTarget.focus();
      zipError.style.display = "none";
    } else {
      const readOnly = data.status != 12; //not_found
      this.setAllReadOnly(false);
      this.stateTarget.readOnly = false;
      zipError.innerText = data.msg;
      zipError.style.display = "block";
    }
  }

  fetchAddressApi(value) {
    fetch(`/api/v1/addresses/${value}`, {
      headers: {accept: 'application/json'}
    }).then((response) => response.json())
      .then(data => this.processAddressesApiResponse(data))
  }

  keydownOnZip(event) {
    const key = event.keyCode || event.charCode;
    length = this.zipcodeTarget.value.length;
    if(key == 8) { // Delete keydown
      document.getElementById("zipcode-js-error").style.display = "none";
      if(length == 3 || length == 7) { // first (.) and second (-) separators
        this.zipcodeTarget.value = this.zipcodeTarget.value.slice(0, -1);
      }
    }
  }

  maskZipField(numbers, length) {
    if(length < 2) {
      this.zipcodeTarget.value = numbers;
    } else if(length == 2) {
      this.zipcodeTarget.value = `${numbers}.`;
    } else if(length < 5) {
      this.zipcodeTarget.value = `${numbers.slice(0, 2)}.${numbers.slice(2,)}`;
    } else if(length == 5) {
      this.zipcodeTarget.value = `${numbers.slice(0, 2)}.${numbers.slice(2,)}-`;
    } else {
      this.zipcodeTarget.value = `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}-${numbers.slice(5,)}`;
    }
  }

  requestAddressesApi() {
    let val = this.zipcodeTarget.value;
    let numbers = val.replace(/\D/g, '');
    let length = numbers.length;

    this.maskZipField(numbers, length);

    if(length == 8) {
      this.fetchAddressApi(numbers);
    } else {
      this.setTargetUsingValues('', '', '', '', '');
    }
  }

  putRealtorZipcode() {
    const selectedRealtorUuid = this.realtorSelectTarget.selectedOptions[0].value

    fetch(`/manager/realtor/address?uuid=${selectedRealtorUuid}`, {
      headers: {accept: 'application/json'}
    }).then((response) =>{ return response.json() })
      .then(data => { this.zipcodeTarget.value = data; this.requestAddressesApi(); })
  }
}
