import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["hiddenMultipleFilesInput", "fileDiv"]

  connect() {
    super.connect()
  }

  addFile(event) {
    this.hiddenMultipleFilesInputTarget.click();
    event.preventDefault();
  }

  removeFile(event) {
    event.target.parentNode.remove()
  }

  fileChoosed(event) {
    const cloneParent = this.fileDivTarget.cloneNode(true)
    cloneParent.classList.add('mb-2')
    let clone_input = cloneParent.querySelector("input");
    clone_input.value = event.currentTarget.value
      .split(/(\\|\/)/g)
      .pop();

    this.fileDivTarget.parentNode.append(cloneParent)
    event.target.value = ""

    if(clone_input.value){
      cloneParent.classList.remove('hidden')
    } else {
      cloneParent.classList.add('hidden')
    }
  }
}
