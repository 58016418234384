import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["slide", "notarize"];

  connect() {
    super.connect()
  }

  toggleSwitch() {
    if (this.notarizeTarget.value === 'true') {
      this.slideTarget.classList.remove('translate-x-5')
      this.slideTarget.classList.add('translate-x-0')
      this.notarizeTarget.value = false
    } else {
      this.slideTarget.classList.remove('translate-x-0')
      this.slideTarget.classList.add('translate-x-5')
      this.notarizeTarget.value = true
    }
  }
}
