// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    const element = this.sourceTarget
    element.select()
    navigator.clipboard ? navigator.clipboard.writeText(element.defaultValue) : document.execCommand("copy")
    const button = event.currentTarget
    button.focus()
    window.getSelection().removeAllRanges();
    const previousContent = button.innerHTML

    if (element.dataset.clipboardNoIcon === 'true') {
      button.innerHTML = '<div class="flex mx-auto text-center focus:border-blue-500 active:border-blue-500"><span class="text-center text-blue-500 m-auto">Copiado!</span></div>'
    } else if (element.dataset.clipboardIconOnly === 'true') {
      button.innerHTML = '<svg class="fill-current w-3 h-3 mr-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>'
    } else {
      button.innerHTML = '<div class="flex mx-auto text-center focus:border-blue-500 active:border-blue-500"><svg class="fill-current w-3 h-3 my-1.5 mx-1.5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="#3b82f6" d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg><span class="text-center text-blue-500 m-auto">Copiado!</span></div>'
    }

    setTimeout(() => {
      button.innerHTML = previousContent
    }, 3000)
  }

  share(event) {
    const element = this.sourceTarget;
    element.select();
    if (navigator.share) {
      try {
        navigator.share({
          text: element.defaultValue
        });
      } catch (error) {
        console.error("Erro ao compartilhar:", error);
      }
    }
  }
}
