import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["name", "datalist", "uuid"];
  // CharCode     8203        8204        8205        65279
  encodeList = ['&#x200b;', '&#x200c;', '&#x200d;', '&#xfeff;'];
  encodeHash = {8203: 0, 8204: 1, 8205: 2, 65279: 3};

  formatDocument(document) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  processPropertiesResponse(data) {
    let options = '';
    for (let i = 0; i < data.length; i++) {
      options += `<option id=customer_opt_${i} uuid="${data[i].uuid}">
                    ${data[i].legal_name}&#10;(${this.formatDocument(data[i].tax_document)})${this.encodeNumber(i)}
                 </option>`;

    }
    this.datalistTarget.innerHTML = options;
  }

  fetchProperties(value) {
    fetch(`/manager/customers/search?name=${value}`, {
      headers: {accept: 'application/json'}
    }).then((response) => response.json())
      .then(data => this.processPropertiesResponse(data))
  }

  optionSelected(inputValue) {
    let optionIdx = this.decodeToNumber(inputValue);
    let option = this.datalistTarget.querySelector(`#customer_opt_${optionIdx}`)
    let uuid = option.getAttribute("uuid");
    this.uuidTarget.value = uuid;
    this.nameTarget.value = inputValue.slice(0, -2).replace(/\s\(\d{3}.\d{3}.\d{3}-\d{2}\)/, '');
  }

  propertiesByName() {
    let inputValue = this.nameTarget.value;
    if(inputValue.charCodeAt(inputValue.length - 1) > 8000) {
      //Customer name selected from list
      this.optionSelected(inputValue);
    } else {
      //Querying for names
      this.uuidTarget.value = "";
      this.fetchProperties(inputValue);
    }
  }

  //number < 16
  encodeNumber(number) {
    let lsbs = number & 3; //0011
    let msbs = (number & 12) >>> 2; //1100
    return this.encodeList[msbs] + this.encodeList[lsbs];
  }

  decodeToNumber(code) {
    let ls_encode = code.charCodeAt(code.length - 1);
    let ms_encode = code.charCodeAt(code.length - 2);
    return (this.encodeHash[ms_encode] << 2) | this.encodeHash[ls_encode]
  }
}
