import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['btnConfirm'];

  connect() {
    this.btnConfirmTarget.classList.add('bg-opacity-25');
  }

  toggleButton(event) {
    if (event.target.checked) {
      this.btnConfirmTarget.removeAttribute('disabled', 'disabled');
      this.btnConfirmTarget.classList.remove('bg-opacity-25');
    } else {
      this.btnConfirmTarget.classList.add('bg-opacity-25');
      this.btnConfirmTarget.setAttribute('disabled', 'disabled');
    }
  }
}
