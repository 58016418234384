import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["attachLink", "checkBox", "document", "fileBox", "fileName", "fileError", "dueAt"];

  cycleStyle() {
    if (this.checkBoxTarget.checked) {
      this.dueAtTarget.removeAttribute("min");
      this.attachLinkTarget.classList.remove("hidden");
    } else {
      const currentDate = new Date();
      this.dueAtTarget.setAttribute("min", currentDate.toISOString().split("T")[0]);
      this.attachLinkTarget.classList.add("hidden");
      this.resetDocument();
    }
  }

  attachDocument() {
    if (!this.checkBoxTarget.checked) return;

    this.documentTarget.click();
  }

  setDocumentName() {
    if (this.isInvalidFile()) {
      this.resetDocument();
      this.fileErrorTarget.classList.remove("hidden");
      return;
    }

    this.fileBoxTarget.classList.add("hidden");
    this.fileNameTarget.innerHTML = this.truncatedFileName();
    this.fileBoxTarget.classList.remove("hidden");
  }

  resetDocument() {
    this.fileBoxTarget.classList.add("hidden");
    this.fileErrorTarget.classList.add("hidden");
    this.documentTarget.value = this.documentTarget.defaultValue;
    this.fileNameTarget.innerHTML = "";
  }

  isInvalidFile() {
    let maxFileSize = this.element.getAttribute("data-payments-maxSize");
    let fileSize = this.documentTarget.files.item(0).size / 1024 / 1024; // in MB
    let invalidSize = fileSize > maxFileSize;
    let validFileTypes = this.element.getAttribute("data-payments-extensions");
    let fileType = this.documentTarget.files.item(0).type;
    fileType = fileType.substring(fileType.indexOf("/") + 1,);
    let invalidType = !validFileTypes.includes(fileType);

    return invalidSize || invalidType;
  }

  truncatedFileName() {
    let fileName = this.documentTarget.files.item(0).name;
    let textLenght = parseInt(this.element.getAttribute("data-payments-truncate"));
    return this.truncate(fileName, textLenght);
  }

  truncate(n, len) {
    let ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    let filename = n.replace("." + ext, "");
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
    return filename + "." + ext;
  }

}
