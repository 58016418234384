import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["participantsForm", "propertyIdentifier", 'select'];

  connect() {
    super.connect()
  }

  addParticipant() {
    const participantName = document.getElementsByName("participant[name]")[0].value;
    const participantEmail = document.getElementsByName("participant[email]")[0].value;
    const participantDocument = document.getElementsByName("participant[cpf]")[0].value;
    const participantTitle = document.getElementsByName("participant[title]")[0].value;

    const cloneNode = this.participantsFormTarget.cloneNode(true)
    const inputs = cloneNode.getElementsByTagName('input')
    const spans = cloneNode.getElementsByTagName('span')

    const participant_infos = [participantName, participantDocument, participantTitle, participantEmail]

    participant_infos.slice(0, participant_infos.length).map((participant_info, index) => {
      inputs[index].value = participant_info
      spans[index].innerText = participant_info
    })

    this.participantsFormTarget.parentNode.appendChild(cloneNode)
  }

  addProperty() {
    let selected_property = this.selectTarget.options[this.selectTarget.selectedIndex].value

    if (selected_property == 'new_property') {
      this.propertyIdentifierTarget.classList.remove('hidden')
    } else {
      this.propertyIdentifierTarget.classList.add('hidden')
    }
  }
}
