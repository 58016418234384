import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['startDateInput', 'endDateInput', 'filterForm'];

  applyFilter() {
    const startDateValue = this.startDateInputTarget.value;
    const endDateValue = this.endDateInputTarget.value;
    
    if ( (startDateValue !== '') && (endDateValue !== '') ) {
      if (this.startDateInputTarget.valueAsDate > this.endDateInputTarget.valueAsDate) {
        alert(this.data.get('invalidPeriodMessage'));
        return;
      }
    }

    this.getControllerByIdentifier('loading-modal').show();
    this.filterFormTarget.submit();
  }

  afterReflex (element, reflex, noop, reflexId) {
    this.getControllerByIdentifier('loading-modal').hide();
    this.getControllerByIdentifier('banking--sidebar').show();
  }
}
