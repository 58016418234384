import {Controller} from "stimulus";

export default class extends Controller {

  static targets = ['filterForm'];

  filter() {
    this.filterFormTarget.submit();
  }
}
