import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "numberOfMonths", "anticipationKind", "startsFrom", "dueAt", "notes", "installmentsTable"]

  previewForm() {
    const numberOfMonths = document.getElementsByName("installments_anticipation_request[number_of_months]")[0].value;
    const installmentKindSelect = document.getElementsByName("installments_anticipation_request[installment_kinds]")[0];
    const installmentKindValue = installmentKindSelect.options[installmentKindSelect.selectedIndex].text;
    const startsFromSelect = document.getElementsByName("installments_anticipation_request[starts_from]")[0];
    const sartsFromValue = startsFromSelect.options[startsFromSelect.selectedIndex].text;
    const dueAt = document.getElementsByName("installments_anticipation_request[due_at]")[0].value;
    const parts = dueAt.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    const notes = document.getElementsByName("installments_anticipation_request[description]")[0].value;

    this.numberOfMonthsTarget.value = numberOfMonths;
    this.anticipationKindTarget.value = installmentKindValue;
    this.startsFromTarget.value = sartsFromValue;
    this.dueAtTarget.value = formattedDate;
    this.notesTarget.innerText = notes;

    this.installmentsTableTarget.classList.remove("hidden");
  }

  hidePreview() {
    this.installmentsTableTarget.classList.add("hidden");
  }
}
