import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['dropdown', 'hidden', 'input']

  select(event) {
    this.hiddenTarget.value = event.currentTarget.dataset['uuid'];
    this.inputTarget.value = event.currentTarget.dataset['name'];
    this.dropdownTarget.classList.add('invisible');
  }

  focus(event) {
    this.dropdownTarget.classList.remove('invisible');
  }

  toggle(event) {
    this.dropdownTarget.classList.toggle('invisible')
  }
}
