import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'field']

  connect(){
    this.verify();
  }

  verify() {
    const checks = Array.from(this.checkboxTargets).filter(el => el.checked);

    if(checks.length > 0){
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.fieldTarget.classList.remove('hidden');
  }

  hide() {
    this.fieldTarget.classList.add('hidden');
  }
}
