import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'birthDate'];

  verifyAge() {
    if (!this.birthDateTarget.value) return;

    const birthDate = new Date(this.birthDateTarget.value);
    const today = new Date();
    const month = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age < 18) {
      this.formTarget.classList.remove('hidden');
    } else {
      this.formTarget.classList.add('hidden');
    }
  }
}
