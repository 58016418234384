import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['downloadLink'];

  connect() {
    window.stimulusControllers = window.stimulusControllers || {};
    window.stimulusControllers["longDownloads"] = this;
  }

  download() {
    this.disableDownloadLink();
    const downloadId = this.data.get('downloadId');
    window.downloadsChannel.subscribe(downloadId);
  }

  enableDownloadLink() {
    this.downloadLinkTarget.classList.remove('pointer-events-none');
  }

  disableDownloadLink() {
    this.downloadLinkTarget.classList.add('pointer-events-none');
  }
}
