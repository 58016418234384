import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  
  showDetails(event) {
    const customerChargeId = event.currentTarget.dataset.customerChargeId;
    if (!customerChargeId) return;

    this.getControllerByIdentifier('loading-modal').show();
    this.stimulate('Banking::ChargesReflex#show_details', customerChargeId);
  }

  afterReflex (element, reflex, noop, reflexId) {
    this.getControllerByIdentifier('loading-modal').hide();
    this.getControllerByIdentifier('banking--sidebar').show();
  }
}
