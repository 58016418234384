import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["initialDateInput",
                    "finalDateInput",
                    "applyDateRangeButton"];

  change(event) {
    if (event.currentTarget.value != "date_range_filter") {
      window.stimulusControllers.loadingModal.show();

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', 1);

      if (event.currentTarget.multiple) {
        searchParams.delete(event.currentTarget.name);

        Array.from(event.currentTarget.selectedOptions).forEach(option => {
          searchParams.append(event.currentTarget.name, option.value);
        });
      } else {
        const selectedOption = event.currentTarget.selectedOptions[0].value;
        searchParams.set(event.currentTarget.name, selectedOption);
      }

      if (event.currentTarget.name == "month") {
        searchParams.delete("initial_date");
        searchParams.delete("final_date");
      }
      
      window.location.search = searchParams.toString();
    }
  }

  changeDate(event) {
    this.applyDateRangeButtonTarget.classList.add("ring");
  }

  applyDateRange(event) {
    const initialDateValue = this.initialDateInputTarget.value;
    const finalDateValue = this.finalDateInputTarget.value;

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("page", "1");

    if ( (initialDateValue != "") && (finalDateValue != "") ) {
      if (this.initialDateInputTarget.valueAsDate > this.finalDateInputTarget.valueAsDate) {
        alert(this.data.get("invalidPeriodMessage"));
        return;
      } else {
        searchParams.set("month", "date_range_filter");
        searchParams.set("initial_date", initialDateValue);
        searchParams.set("final_date", finalDateValue);
      }
    }
    
    window.location.search = searchParams.toString();
  }
}
