import { Controller } from "stimulus"
import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Chartjs from "chart.js";

export default class extends Controller {
  handleClick(event, element, chart) {
    if (element.length === 0) return;

    const label = chart.data.labels[element[0].index];
    const links = JSON.parse(window.stimulusControllers.charts.data.get('links'));
    window.location = links[label];
  }

  connect() {
    window.stimulusControllers = window.stimulusControllers || {};
    window.stimulusControllers["charts"] = this;

    const chart = document.getElementById('aging_balance_chart');

    if (!chart) return;

    let container = chart.getContext('2d');

    let data = JSON.parse(this.data.get('jsonData'));
    let max_value = this.data.get('maxValue');
    let options = {
      layout: {
        padding: {
          top: 40
        }
      },
      plugins: {
        datalabels: {
          align: 'end',
          anchor: 'end',
          borderWidth: 0,
          color: '#000000',
          font: {
            size: 12,
            weight: 600
          },
          offset: 2,
          padding: 2,
          formatter: function(value, context) {
            return Math.round((value * 100) / max_value) + " %"
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              if (context.parsed.y !== null) {
                return context.parsed.y.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
              }
              return '';
            }
          }
        },
        legend: { display: false }
      },
      tooltips : {
        enabled: true,
        mode: 'single'
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            beginAtZero: false,
            callback: function(value, index, values) {
              return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
            },
            font: {
              size: 12
            },
            color: '#000000'
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 12,
              weight: 600
            },
            color: '#000000'
          }
        }
      },
      onClick: this.handleClick,
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    }

    data["options"] = options;

    const controllers = Object.values(Chartjs).filter(
      (chart) => chart.id !== undefined
    );
    
    Chart.register(...controllers);
    Chart.register(ChartDataLabels);

    this.chart = new Chart(container, data);
  }
}
