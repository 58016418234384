import { Controller } from 'stimulus';

export default class extends Controller {
  change(event) {
    window.stimulusControllers.loadingModal.show();

    const selectedOption = event.currentTarget.selectedOptions[0].value;
    const searchParams = new URLSearchParams(window.location.search);
    
    searchParams.set('page', 1);
    searchParams.set(event.currentTarget.name, selectedOption);

    window.location.search = searchParams.toString();
  }
}
