import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['menuItem', 'menuItemIcon', 'logo', 'logoExtended', 'accountBalance',
                    'accountBalanceAmount', 'accountBalanceShowBtn', 'accountBalanceHideBtn',
                    'accountBalanceFetchedAt', 'sidebar'];

  static values = { wallet: String }

  connect() {
    super.connect();

    setTimeout(() => this.fetchAccountBalance(), 500);
  }

  fetchAccountBalance() {
    this.accountBalanceAmountTarget.innerText = 'R$ [...]';
    this.accountBalanceFetchedAtTarget.innerText = 'atualizando...';
    this.stimulate('Banking::AccountsReflex#update_balance');
  }

  hideBalanceAmount(event) {
    event.preventDefault();
    event.stopPropagation();

    this.accountBalanceAmountTarget.innerText = "******";
    this.accountBalanceHideBtnTarget.classList.add('hidden');
    this.accountBalanceShowBtnTarget.classList.remove('hidden');
  }

  showBalanceAmount(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fetchAccountBalance();
    this.accountBalanceShowBtnTarget.classList.add('hidden');
    this.accountBalanceHideBtnTarget.classList.remove('hidden');
  }

  refreshBalanceAmount(event) {
    event.preventDefault();
    event.stopPropagation();

    this.accountBalanceAmountTarget.innerText = 'R$ [...]';
    this.accountBalanceFetchedAtTarget.innerText = 'atualizando...';
    this.stimulate('Banking::AccountsReflex#update_balance', true);
  }
}
