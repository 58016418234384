import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static values = { method: String, delay: Number }

    debounce(){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.stimulate(this.methodValue), this.delayValue || 500);
    }
}
