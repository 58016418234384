import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['phone', 'email', 'submit']

  connect() {
    this.phoneTarget.oldValue = this.phoneTarget.value.replace(/\D/g,'');
    this.emailTarget.oldValue = this.emailTarget.value.trim()
    let callback = this.onChange
    this.phoneTarget.addEventListener('keyup', callback.bind(this));
    this.emailTarget.addEventListener('keyup', callback.bind(this))
    this.submitEnabledKlasses = this.submitTarget.dataset.enabled.split(' ');
    this.submitDisabledKlasses = this.submitTarget.dataset.disabled.split(' ');
    this.onChange()
  }

  onChange() {
    if (this.phoneChanged() || this.emailChanged()) {
      this.enableSubmit();
    } else this.disableSubmit();
  }

  enableSubmit(){
    this.submitTarget.classList.remove(...this.submitDisabledKlasses);
    this.submitTarget.classList.add(...this.submitEnabledKlasses);
    this.submitTarget.disabled = false;
  }

  disableSubmit(){
    this.submitTarget.classList.remove(...this.submitEnabledKlasses);
    this.submitTarget.classList.add(...this.submitDisabledKlasses);
    this.submitTarget.disabled = true;
  }

  phoneChanged(){
   return this.phoneTarget.value.replace(/\D/g,'') != this.phoneTarget.oldValue;
  }

  emailChanged(){
    return this.emailTarget.value.trim() != this.emailTarget.oldValue;
  }

}
