import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['items', 'currentValue', 'select', 'all'];

  apply(event) {
    const checked = Array.from(this.itemsTargets).filter(element => element.checked);
    const values = checked.map(element => element.value);
    const options = this.selectTarget.options;

    for (let i = 0; i < options.length; i++) {
      options[i].selected = values.includes(options[i].value);
    }
  
    this.selectTarget.dispatchEvent(new Event('change'));

    if (checked.length == 0) {
      this.currentValueTarget.innerText = this.data.get('placeholder');
    } else if (checked.length == 1) {
      this.currentValueTarget.innerText = checked[0].dataset.label;
    } else if (checked.length == this.itemsTargets.length) {
      this.currentValueTarget.innerText = "Todos";
    } else {
      this.currentValueTarget.innerText = `${checked.length} selecionados`;
    }
  }

  change(event) {
    const checked = Array.from(this.itemsTargets).filter(element => element.checked);

    if (checked.length == this.itemsTargets.length) {
      this.allTarget.checked = true;
    } else {
      this.allTarget.checked = false;
    }
  }

  toggleAll(event) {
    const checked = event.target.checked;
    const items = this.itemsTargets;

    for (let i = 0; i < items.length; i++) {
      items[i].checked = checked;
    }
  }
}
