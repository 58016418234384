import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['organizations', 'realtors', 'form', 'btnApply'];

  realtorsGroupedByOrganization = { root: [] }

  connect() {
    const instance = this;
    instance.realtorsTargets.forEach(element => {
      if (element.dataset.organization) {
        if (!instance.realtorsGroupedByOrganization[element.dataset.organization]) {
          instance.realtorsGroupedByOrganization[element.dataset.organization] = [];
        }
        instance.realtorsGroupedByOrganization[element.dataset.organization].push(element);
      } else {
        instance.realtorsGroupedByOrganization['root'].push(element)
      }
    });
  }

  reload() {
    window.location.reload();
  }

  toggleOrganization(event) {
    this.toogleRealtors(event.target.value, event.target.checked);
    this.toogleButtonApply();
  }

  toogleButtonApply() {
    const disabled = this.realtorsTargets.every(element => !element.checked);

    if (disabled) {
      this.btnApplyTarget.setAttribute('disabled', 'disabled');
      this.btnApplyTarget.classList.add('bg-opacity-25');
    } else {
      this.btnApplyTarget.removeAttribute('disabled');
      this.btnApplyTarget.classList.remove('bg-opacity-25');
    }
  }

  toggleRealtor(event) {
    const target = event.target;

    if (!target.dataset.organization) {
      this.toogleButtonApply();

      return;
    }

    if (target.checked && this.allRealtorsChecked(target.dataset.organization)) {
      this.findOrganization(target.dataset.organization).checked = true;
    } else if (!target.checked) {
      this.findOrganization(target.dataset.organization).checked = false;
    }

    this.toogleButtonApply();
  }

  toogleRealtors(organization, checked) {
    if (this.realtorsGroupedByOrganization[organization]) {
      if (checked) {
        this.realtorsGroupedByOrganization[organization].forEach(element => element.checked = true);
      } else {
        this.realtorsGroupedByOrganization[organization].forEach(element => element.checked = false);
      }
    }
  }

  allRealtorsChecked(organization) {
    if (organization) {
      return this.realtorsGroupedByOrganization[organization].every(element => element.checked);
    }

    return false;
  }

  findOrganization(uuid) {
    return this.organizationsTargets.find(element => element.value == uuid);
  }

  applyRealtorsSelection(event) {
    Rails.fire(this.formTarget, 'submit');
  }
}
