import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['buttons', 'counter'];

  advance() {
    this.buttonsTarget.classList.remove('flex-row-reverse');
    this.counterTarget.innerText = "2"
  }

  back() {
    this.buttonsTarget.classList.add('flex-row-reverse');
    this.counterTarget.innerText = "1"
  }
}
