import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['whatsappOrder'];

  connect() {
    const searchParams = new URLSearchParams(window.location.search);
    if (this.whatsappOrderTarget) {
      this.whatsappOrderTarget.value = searchParams.get('order') || 'desc';
    }
  }

  changeWhatsappOrder(event) {
    window.stimulusControllers.loadingModal.show();

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', 1);
    searchParams.set('order', event.currentTarget.selectedOptions[0].value);

    window.location.search = searchParams.toString();
  }
}
