import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['tab', 'panel']
  
  initialize() {
    this.activeTabClasses = this.data.get('activeClass').split(' ')
    this.unactiveTabClasses = this.data.get('class').split(' ')

    this.tabToShow = document.location.hash.substring(1)
    this.showTab()
  }

  change(event) {
    event.preventDefault()
    
    this.tabToShow = event.target.getAttribute('href').substring(1)
    
    const actualActive = this.tabTargets.find(el => el.getAttribute('aria-current') === "page")

    actualActive.removeAttribute('aria-current')
    actualActive.classList.add(...this.unactiveTabClasses)
    event.target.classList.add(... this.activeTabClasses)
    
    actualActive.classList.remove(...this.activeTabClasses)
    event.target.classList.remove(...this.unactiveTabClasses)
    event.target.setAttribute('aria-current', 'page')
    this.showTab()
  }

  showTab() {
    if (this.tabToShow === '') {
      return null
    }
    
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
        
      if (panel.id === this.tabToShow) {
         panel.classList.remove('hidden')
       }  else {
        panel.classList.add('hidden')
      }
    })
  }
}
