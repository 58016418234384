import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['sidebar'];

  show(event) {
    this.sidebarTarget.classList.remove('hidden');
  }

  close() {
    this.sidebarTarget.classList.add('hidden');
  }
}
