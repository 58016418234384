import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.close()
    }, 12000)
  }

  close() {
    this.element.classList.add('hidden')
  }
}
