import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['startDateInput', 'endDateInput'];
  
  applyFilter(event) {
    const startDateValue = this.startDateInputTarget.value;
    const endDateValue = this.endDateInputTarget.value;

    const searchParams = new URLSearchParams(window.location.search);

    if ( (startDateValue !== '') && (endDateValue !== '') ) {
      if (this.startDateInputTarget.valueAsDate > this.endDateInputTarget.valueAsDate) {
        alert(this.data.get('invalidPeriodMessage'));
        return;
      } 
      else {
        searchParams.set('start_date', startDateValue);
        searchParams.set('end_date', endDateValue);
      }
    }
    else {
      searchParams.delete('start_date');
      searchParams.delete('end_date');
    }

    searchParams.set('transactions', searchParams.get('transactions') || 'all');

    window.location.search = searchParams.toString();
  }
}
