import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menuItem', 'logo', 'logoExtended'];

  expand() {
    this.logoExtendedTarget.classList.remove('hidden');
    this.logoTarget.classList.add('hidden');
    this.menuItemTargets.forEach(item => {
      item.classList.add('ml-3');
      item.classList.remove('invisible');
    });
  }

  collapse() {
    this.logoExtendedTarget.classList.add('hidden');
    this.logoTarget.classList.remove('hidden');
    this.menuItemTargets.forEach(item => {
      item.classList.remove('ml-3');
      item.classList.add('invisible');
    });
  }
}
