import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['items', 'subItems', 'currentValue', 'submit', 'button', 'values', 'amount', 'formInput', 'states'];

  connect() {
    this.apply();
  }

  handleOverdueCheckboxChange() {
    const checkedSubItems = Array.from(this.subItemsTargets).filter(element => element.checked);

    if(!this.itemsTargets[0].checked){
      checkedSubItems.forEach(e => e.checked = false);
    }

    this.apply();
  }

  handleOverdueSubCheckboxesChange() {
    const checkedSubItems = Array.from(this.subItemsTargets).filter(element => element.checked);

    if (checkedSubItems.length > 0) {
      this.itemsTargets[0].checked = true;
    }

    this.apply();
  }

  apply(event) {
    const checked = Array.from(this.itemsTargets).filter(element => element.checked);
    let totalAmount = 0;
    const checkedSubItems = Array.from(this.subItemsTargets).filter(element => element.checked);
    const statesArray = checked.map(e => e.defaultValue);

    if(this.itemsTargets[2].checked){
      this.itemsTargets.forEach(e => {
        if(e.dataset.amount != 0){
          e.checked = true
          const amount = parseFloat(e.dataset.amount);
          totalAmount += amount;
        }
      });
      this.subItemsTargets.forEach(e => {
        if(e.dataset.amount != 0){
          e.checked = true
          const amount = parseFloat(e.dataset.amount || 0);
          totalAmount += amount;
        }
      });
    } else {
      checked.forEach(c => {
        const amount = parseFloat(c.dataset.amount || 0);
        totalAmount+= amount;
      });
      checkedSubItems.forEach(c => {
        const amount = parseFloat(c.dataset.amount || 0);
        totalAmount += amount;
      });
    }

    const formattedAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(totalAmount);

    this.formInputTarget.textContent = totalAmount;
    this.amountTarget.innerText = formattedAmount;
    this.formInputTarget.value = totalAmount;
    this.statesTarget.value = statesArray;

    if (checked.length != 0) {
      this.currentValueTarget.innerText = `${checked.length} selecionado(s)`;
      this.submitTarget.classList.remove('pointer-events-none');
      this.buttonTarget.classList.remove('bg-indigo-300');
      this.buttonTarget.classList.add('bg-bright-blue');
    } else {
      this.currentValueTarget.innerText = this.currentValueTarget.getAttribute('placeholder');
      this.submitTarget.classList.add('pointer-events-none');
      this.buttonTarget.classList.add('bg-indigo-300');
      this.buttonTarget.classList.remove('bg-bright-blue');
    }
  }
}
