import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "discountAmountInput",
    "totalInstallmentsInput",
    "totalAmountLabel",
    "installmentAmountLabel",
    "totalAmountInput"
  ];

  connect() {
    this.recalculateAmounts();
  }

  recalculateAmounts() {
    const totalAmount = parseFloat(this.totalAmountInputTarget.textContent) || 0;
    const discountAmount = parseFloat(this.discountAmountInputTarget.value) || 0;

    const amount = totalAmount - discountAmount;

    this.totalAmountLabelTarget.innerText = amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });

    const totalInstallments = parseInt(this.totalInstallmentsInputTarget.value);
    if (!totalInstallments || totalInstallments < 1) return;

    const amountPerInstalment = amount / totalInstallments;
    this.installmentAmountLabelTarget.innerText = amountPerInstalment.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }
}
