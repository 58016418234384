import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  showDetails(event) {
    const exportItemId = event.currentTarget.dataset.exportItemId;
    if (!exportItemId) return;

    this.getControllerByIdentifier('loading-modal').show();
    this.stimulate('Banking::FileExportsReflex#show_details', exportItemId);
  }

  afterReflex(element, reflex, noop, reflexId) {
    this.getControllerByIdentifier('loading-modal').hide();
    this.getControllerByIdentifier('banking--sidebar').show();
  }
}
