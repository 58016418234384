import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hiddenElement', 'inputData', 'hiddenIcon', 'hiddenForm', 'birthDate', 'fullName', 'email', 'phone', 'role', 'partToEdit'];

  clearData() {
    this.inputDataTarget.value = null
    this.fullNameTarget.value = null
    this.emailTarget.value = null
    this.phoneTarget.value = null
    this.birthDateTarget.value = null
    this.roleTarget.value = null

    this.hiddenElementTarget.classList.add('h-0')
    this.hiddenIconTarget.classList.add('hidden')
    this.hiddenFormTarget.classList.add('hidden')
  }

  fetchCustomer(value) {
    fetch(`/sign/customers/search?tax_document=${value}`, {
      headers: {accept: 'application/json'}
    }).then((response) => response.json())
      .then(data => {
        this.fullNameTarget.value = data.legal_name
        this.emailTarget.value = data.email
        this.phoneTarget.value = data.phone_number
        this.birthDateTarget.value = data.birth_date
      })
  }

  fillData(){
    this.inputDataTarget.value = this.partToEditTarget.textContent
    this.fetchCustomer(this.inputDataTarget.value)
    this.hiddenElementTarget.classList.remove('h-0')
    this.hiddenIconTarget.classList.remove('hidden')
    this.hiddenFormTarget.classList.remove('hidden')
  }

  verifyData(){
    if(this.inputDataTarget.value.length == 11 || this.inputDataTarget.value.length == 14){
      this.fetchCustomer(this.inputDataTarget.value)
      this.hiddenElementTarget.classList.remove('h-0')
      this.hiddenIconTarget.classList.remove('hidden')
      this.hiddenFormTarget.classList.remove('hidden')
    } else {
      this.hiddenElementTarget.classList.add('h-0')
      this.hiddenIconTarget.classList.add('hidden')
      this.hiddenFormTarget.classList.add('hidden')
    }
  }
}
